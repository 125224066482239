import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Homepage1Component } from './components/pages/homepage1/homepage1.component';
import { Homepage2Component } from './components/pages/homepage2/homepage2.component';
import { Homepage3Component } from './components/pages/homepage3/homepage3.component';
import { Homepage4Component } from './components/pages/homepage4/homepage4.component';
import { Homepage5Component } from './components/pages/homepage5/homepage5.component';
import { AboutusComponent } from './components/pages/aboutus/aboutus.component';
import { ShopLeftComponent } from './components/pages/shop-left/shop-left.component';
import { ShopRightComponent } from './components/pages/shop-right/shop-right.component';
import { ShopDetailComponent } from './components/pages/shop-detail/shop-detail.component';
import { DonationArchiveComponent } from './components/pages/donation-archive/donation-archive.component';
import { DonationDetailComponent } from './components/pages/donation-detail/donation-detail.component';
import { WishlistComponent } from './components/pages/wishlist/wishlist.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { OrderComponent } from './components/pages/order/order.component';
import { Error404Component } from './components/pages/error404/error404.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { BlogRightComponent } from './components/pages/blog-right/blog-right.component';
import { BlogLeftComponent } from './components/pages/blog-left/blog-left.component';
import { BlogDetailComponent } from './components/pages/blog-detail/blog-detail.component';
import { StoryArchiveComponent } from './components/pages/story-archive/story-archive.component';
import { StoryDetailComponent } from './components/pages/story-detail/story-detail.component';
import { ContactComponent } from './components/pages/contact/contact.component';

const routes: Routes = [

  { path: '', component: Homepage1Component, data: { breadcrumb: 'Inicio' } },
  { path: 'nosotros', component: AboutusComponent, data: { breadcrumb: 'Nosotros' } },
  { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' } },
  { path: 'register', component: RegisterComponent, data: { breadcrumb: 'Register' } },
  { path: 'candidatos', component: StoryArchiveComponent, data: { breadcrumb: "Candidatos" } },
  { path: 'candidato/:id', component: StoryDetailComponent, data: { breadcrumb: 'Candidato' } },
  { path: '**', component: Error404Component, data: { breadcrumb: '404' } }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
