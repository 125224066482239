import { Component, OnInit, HostListener } from '@angular/core';
import * as $ from 'jquery'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  windowScrolled: boolean | undefined;
  constructor() { }
  // Sticky Nav
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.windowScrolled = window.scrollY > 100;
  }
  check: boolean = true;
  hamburgerbtn() {
    this.check = !this.check;
  }

  // Logo
  logo = 'assets/img/logotipo.png';
  ngOnInit(): void {
    function navigation() {
      // Navigation
      var navMan = $('.main-menu li.menu-item-has-children>a, .main-menu li.menu-item-has-megamenu>a');
      $(navMan).on('click', function () {
        $(navMan).removeAttr('href');
        var element = $(navMan).parent('li');
        if (element.hasClass('open')) {
          element.removeClass('open');
          element.find('li').removeClass('open');
          element.find('ul, .megamenu').slideUp();
        } else {
          element.addClass('open');
          element.children('ul, .megamenu').slideDown();
          element.siblings('li').children('ul, .megamenu').slideUp();
          element.siblings('li').removeClass('open');
          element.siblings('li').find('li').removeClass('open');
          element.siblings('li').find('ul, .megamenu').slideUp();
        }
      });
      $('.menu-item-has-children>a').append('<span class="arrow"></span>');
      $('.menu-item-has-megamenu>a').append('<span class="arrow"></span>');
    }
    navigation();
  }

}
