import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  // Variables para el banner y los datos del usuario
  universidades: any[] = [];
  banner = 'assets/img/slider.jpg';
  name: string = '';
  lastname: string = '';
  about: string = '';
  avatar: string = ''; // Guardará la URL de la imagen carga
  description: string = '';
  name_estado: string = '';
  from: string = '';
  name_municipio: string = '';

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchUserData();
    this.getUniversidades().subscribe((data) => {
      this.universidades = data.results;
    });
  }

  getUniversidades(): Observable<any> {
    return this.http.get('assets/json/universidades.json'); // Asegúrate de que el archivo esté en la carpeta 'assets'
  }
  // Función para consultar datos del usuario al cargar el componente
  async fetchUserData() {
    const authToken = localStorage.getItem('authToken');

    // Redirigir si no hay token
    if (!authToken) {
      this.router.navigate(['/']);
      return;
    }

    try {
      const response = await fetch('https://thebackend-api.com/fveu-get', {
        method: 'GET',
        headers: {
          Authorization: authToken,
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener los datos del usuario');
      }

      const data = await response.json();
      let dat = data?.data
      this.name = dat.name;
      this.lastname = dat.lastname;
      this.about = dat.about;
      this.avatar = dat.avatar;
      this.description = dat.description;
      this.from = dat.from;
      this.name_estado = dat.name_estado;
      this.name_municipio = dat.name_municipio;
      console.log(this.name);
      console.log(dat);
    } catch (error) {
      console.error('Error en fetchUserData:', error);
      this.router.navigate(['/']);
    }
  }

  // Manejo del archivo seleccionado
  async onFileSelected(event: any) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      this.router.navigate(['/']);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://thebackend-api.com/s3-upload', {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error al cargar el archivo');
      }

      const data = await response.json();

      if (data.status) {
        this.avatar = data.data; // Guardar la URL devuelta en el modelo avatar
        console.log('Archivo subido con éxito:', this.avatar);
      } else {
        console.error('Error al subir el archivo:', data.message);
      }
    } catch (error) {
      console.error('Error en onFileSelected:', error);
    }
  }
  // Función para enviar datos del formulario
  async submitForm(formData: {
    name: string;
    lastname: string;
    from: string;
    about: string;
    description: string;
    avatar: string;
  }) {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      this.router.navigate(['/']);
      return;
    }
    console.log(formData)
    try {
      const response = await fetch('https://thebackend-api.com/fveu-created', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Error al enviar los datos del formulario');
      }

      const data = await response.json();
      if (data.status === true) {
        this.router.navigate(['/']);
      }
      console.log('Formulario enviado con éxito:', data);
    } catch (error) {
      console.error('Error en submitForm:', error);
    }
  }
}
