<app-topbar></app-topbar>
<app-navbar></app-navbar>
<app-breadcrumbs></app-breadcrumbs>

<section class="our-articles bg-light-theme section-padding">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-xl-12">
				<!-- Formulario de búsqueda -->
				<div class="search-bar mb-4">
					<form (ngSubmit)="fetchCandidato()">
						<div class="row">
							<div class="col-md-4">
								<input type="text" class="form-control" [(ngModel)]="searchParams.name" name="name"
									placeholder="Buscar por nombre" />
							</div>
							<div class="col-md-4">
								<select class="form-control js-example-basic-single" id="universidades-select"
									[(ngModel)]="searchParams.from" name="universidad">
									<option value="" disabled selected>Selecciona una universidad</option>
									<option value="">TODAS</option>
									<option *ngFor="let universidad of universidades" [value]="universidad.text">
										{{ universidad.text }}
									</option>
								</select>
							</div>
							<div class="col-md-4 ">
								<button type="submit" class="btn btn-primary">Buscar</button>
							</div>
						</div>
					</form>
				</div>

				<!-- Lista de candidatos -->
				<div class="row">
					<div class="col-xl-3 col-lg-6 col-md-6" *ngFor="let storybox of candidatos">
						<div class="sa-causes-single sa-causes-single-2">
							<div class="entry-thumb">
								<a routerLink="/candidato/{{ storybox.id }}">
									<img src="{{ storybox.avatar }}" alt="img" class="img-fluid full-width" />
								</a>
								<div class="dontaion-category story-meta">
									<a routerLink="/candidato/{{ storybox.id }}">
										<p>
											<strong>{{ storybox.name }} {{ storybox.lastname }}</strong>
										</p>
									</a>
								</div>
							</div>
							<div class="causes-details-wrap">
								<div class="causes-details">
									<p class="text-danger">{{ storybox.from }}</p>
									<p>{{ storybox.about | slice:0:100 }}{{ storybox.about.length > 100 ? '...' : '' }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Paginación -->
				<nav aria-label="Page navigation" class="mt-4">
					<ul class="pagination justify-content-center">
						<li class="page-item" [class.disabled]="page === 1">
							<a class="page-link" (click)="changePage(page - 1)">Anterior</a>
						</li>
						<li class="page-item" *ngFor="let p of [].constructor(totalPages()); let i = index"
							[class.active]="i + 1 === page">
							<a class="page-link" (click)="changePage(i + 1)">{{ i + 1 }}</a>
						</li>
						<li class="page-item" [class.disabled]="page === totalPages()">
							<a class="page-link" (click)="changePage(page + 1)">Siguiente</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>