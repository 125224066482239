import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-story-archive',
  templateUrl: './story-archive.component.html',
  styleUrls: ['./story-archive.component.css'],
})
export class StoryArchiveComponent implements OnInit {
  candidatos: any[] = []; // Lista de candidatos
  total: number = 0; // Total de registros
  page: number = 1; // Página actual
  limit: number = 10; // Cantidad de registros por página
  searchParams: any = {
    name: '',
    from: '',
    name_estado: '',
  }; // Parámetros de búsqueda
  universidades: any[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchCandidato();
    this.getUniversidades().subscribe((data) => {
      this.universidades = data.results;
    });
  }

  getUniversidades(): Observable<any> {
    return this.http.get('assets/json/universidades.json'); // Asegúrate de que el archivo esté en la carpeta 'assets'
  }

  // Función para obtener candidatos con paginación y búsqueda
  async fetchCandidato() {
    const queryParams = new URLSearchParams({
      page: this.page.toString(),
      limit: this.limit.toString(),
      ...this.searchParams, // Parámetros de búsqueda
    }).toString();

    try {
      const response = await fetch(`https://thebackend-api.com/candidatos?${queryParams}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Error al obtener los datos del usuario');
      }

      const data = await response.json();
      if (data.status) {
        this.candidatos = data.data.items; // Lista de candidatos
        this.total = data.data.total; // Total de registros
      }
    } catch (error) {
      console.error('Error en fetchCandidato:', error);
    }
  }

  // Cambiar página
  changePage(newPage: number) {
    if (newPage >= 1 && newPage <= this.totalPages()) {
      this.page = newPage;
      this.fetchCandidato();
    }
  }

  // Calcular total de páginas
  totalPages(): number {
    return Math.ceil(this.total / this.limit);
  }
}
