<div class="inner-wrapper">
	<div class="container-fluid no-padding">
		<div class="row no-gutters overflow-auto">
			<div class="col-md-6 d-none d-md-block">
				<div class="main-banner">
					<img src="{{banner}}" class="img-responsive full-width main-img" alt="banner">
				</div>
			</div>
			<div class="col-md-6">
				<div class="section-2 user-page main-padding">
					<div class="login-sec">
						<div class="login-box">
							<form (ngSubmit)="onSubmit()">
								<h4 class="text-light-black fw-600">Inicie sesión con su cuenta</h4>
								<div class="row">
									<div class="col-12">
										<div class="form-group">
											<label class="text-light-white fs-14">Usuario</label>
											<input type="text" name="username" class="form-control form-control-submit"
												[(ngModel)]="username" placeholder="Usuario" required>
										</div>
										<div class="form-group">
											<label class="text-light-white fs-14">Contraseña</label>
											<input [type]="password_type" id="password-field" name="password"
												[(ngModel)]="password" class="form-control form-control-submit" value=""
												placeholder="Password" required>
											<div data-name="#password-field"
												class="fa fa-fw fa-eye field-icon toggle-password" (click)="showPass()">
											</div>
										</div>
										<div slot="helper" *ngIf="isError" class="text-danger"> Datos invalidos, Vuelve
											a
											intentarlo </div>
										<div class="form-group">
											<button type="submit" class="btn-second-2 btn-submit full-width">Iniciar
												sesión</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>