<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!-- about us -->
<section class="aboutus section-padding">
	<div class="container-fluid custom-container">
		<div class="row align-items-center justify-content-between">
			<div class="col-xl-9">
				<div class="history-title mb-lg-40">
					<h2 class="text-light-black">See what we do for the poor people and <span
							class="text-color-primary">the children</span></h2>
					<p class="text-light-white mb-xl-40 fw-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
						sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
						<br>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus
						vel facilisis.</p>
					<h4 class="text-light-black">Klara Montesino</h4>
					<div class="row justify-content-between">
						<div class="col-md">
							<div class="about-box">
								<p class="text-light-white mb-xl-20">
									Lorem Ipsum. Proin gravida nibh vel velit amet auctor aliquet. Aenean sollicitudin,
									lorem quis bibendum nisi elit consequat ipsum, nec ipsu sagittis sebh id elit.
								</p>

							</div>
							<div class="row full-height">
								<div class="col-12 full-height">
									<div class="img-box mb-sm-40">
										<img src="{{ aboutimg }}" class="image-fit" alt="img">
									</div>
								</div>
							</div>
						</div>
						<div class="col-md">
							<div class="about-box">
								<h5 class="btn-text-dark">Klara Montesino</h5>
								<p class="text-light-white mb-xl-20">
									Lorem Ipsum. Proin gravida nibh vel velit amet auctor aliquet. Aenean sollicitudin,
									lorem quis bibendum nisi elit consequat ipsum, nec ipsu sagittis sebh id elit. Duis
									sed odio sit amet nibh vulputatrsus a sit amet mauris morbi ipsum.
								</p>
							</div>
							<div class="about-box">
								<h5 class="btn-text-dark">Klara Montesino</h5>
								<p class="text-light-white mb-xl-20">
									Lorem Ipsum. Proin gravida nibh vel velit amet auctor aliquet. Aenean sollicitudin,
									lorem quis bibendum nisi elit consequat ipsum, nec ipsu sagittis sebh id elit. Duis
									sed odio sit amet nibh vulputatrsus a sit amet mauris morbi ipsum.
								</p>
							</div>
						</div>
						<div class="col-md">
							<div class="about-box ">
								<p class="text-light-white custom-lh mb-xl-20">
									Lorem Ipsum. Proin gravida nibh vel velit amet auctor aliquet. Aenean sollicitudin,
									lorem quis bibendum nisi elit consequat ipsum, nec ipsu sagittis sebh id elit. Duis
									sed odio sit amet nibh vulputatrsus a sit amet mauris morbi ipsum.
								</p>
								<ul>
									<li class="fs-14 text-light-white">
										<i class="fas fa-check"></i>A Fair Tax System
									</li>
									<li class="fs-14 text-light-white">
										<i class="fas fa-check"></i>Climate Change
									</li>
									<li class="fs-14 text-light-white">
										<i class="fas fa-check"></i>Health Consultation
									</li>
									<li class="fs-14 text-light-white">
										<i class="fas fa-check"></i>Gun Violence Prevention
									</li>
								</ul>
								<p class="text-light-black fw-600 mb-1">J. D 2022</p>
								<img src="{{ aboutsign }}" class="mb-xl-20" alt="img">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3">
				<div class="row">
					<div class="col-xl-12 col-md-4 col-sm-6" *ngFor="let quotepost of quoteposts">
						<div class="about-quote-box">
							<h6 class="text-custom-white">{{ quotepost.text }}</h6>
							<p class="text-custom-white">By {{ quotepost.author }}</p>
							<i class="fas fa-quote-right"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- about us -->
<!-- How It Woks -->
<section class="section-padding how-it-works bg-theme-primary">
	<div class="container">
		<div class="section-header-style-2">
			<h6 class="text-light-green sub-title">Our Process</h6>
			<h3 class="text-light-black header-title">How Does It Work</h3>
		</div>
		<div class="row">
			<div class="col-lg-3 col-sm-6" *ngFor="let processpost of processposts">
				<div class="how-it-works-box {{ processpost.arrowclass }}">
					<div class="how-it-works-box-inner">
						<span class="icon-box">
							<img src="{{ processpost.icon }}" alt="icon">
							<span class="number-box">{{ processpost.number }}</span>
						</span>
						<h6>{{ processpost.title }}</h6>
						<p>{{ processpost.description }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- How It Woks -->
<!--Testimonail-->
<app-testimonials></app-testimonials>
<!--Testimonail End-->
<!-- Blog section -->
<app-blogpost></app-blogpost>
<!-- END/BLOG Section -->
<app-newsletter2></app-newsletter2>
<app-footer></app-footer>