<!-- Navigation -->
<header class="header" [ngClass]="windowScrolled ? 'sticky':''">
  <div class="container-fluid custom-container">
    <div class="row">
      <div class="col-12">
        <div class="navigation">
          <div class="logo">
            <a routerLink="/">
              <img src="{{ logo }}" class="image-fit " alt="logo">
            </a>
          </div>
          <div class="main-navigation" [ngClass]="check ? '' : 'active'">
            <nav>
              <ul class="main-menu">
                <li class="menu-item"> <a routerLink="/" class="text-light-black" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Inicio</a>
                </li>
                <li class="menu-item"> <a routerLink="/candidatos" class="text-light-black" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Lista de Candidatos</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="right-side-navigation">
            <ul>
              <li class="hamburger-menu" (click)="hamburgerbtn()">
                <a href="javascript:void(0)" class="menu-btn" [ngClass]="check ? '' : 'active'"> <span></span>
                  <span></span>
                  <span></span>
                </a>
              </li>
              <li class="user-details">
                <a href="/login"> <i class="pe-7s-user"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="main-sec"></div>