import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-story-detail',
  templateUrl: './story-detail.component.html',
  styleUrls: ['./story-detail.component.css']
})
export class StoryDetailComponent implements OnInit {
  candidatoId: string | null = null; // ID del candidato desde la URL
  userData: any = null; // Datos del candidato
  constructor(private route: ActivatedRoute) { }


  ngOnInit(): void {
    // Obtener el parámetro `id` de la URL
    this.candidatoId = this.route.snapshot.paramMap.get('id');

    if (this.candidatoId) {
      this.fetchCandidatoDetails(this.candidatoId);
    }
  }

  // Lógica para obtener los detalles del candidato desde el backend
  async fetchCandidatoDetails(id: string): Promise<void> {
    try {
      const response = await fetch(`https://thebackend-api.com/candidato/${id}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Error al obtener los detalles del candidato');
      }

      const data = await response.json();

      // Guardar los datos del candidato en `userData`
      if (data?.status === true) {
        this.userData = data?.data;
      }
      console.log('Detalles del candidato:', this.userData);
    } catch (error) {
      console.error('Error en fetchCandidatoDetails:', error);
    }
  }

  storybanner = 'assets/img/story-big/mainstory.jpg';
  commentimg1 = "assets/img/blog/user1.jpg";
  commentimg2 = "assets/img/blog/user2.jpg";
  commentimg3 = "assets/img/blog/user3.jpg";
  commentimg4 = "assets/img/blog/user4.jpg";

  // Story Gallery
  storygallerypost = [
    { img: "assets/img/story-big/storyg8.jpg" },
    { img: "assets/img/story-big/storyg7.jpg" },
    { img: "assets/img/story-big/storyg6.jpg" },
    { img: "assets/img/story-big/storyg5.jpg" },
    { img: "assets/img/story-big/storyg4.jpg" },
    { img: "assets/img/story-big/storyg3.jpg" },
    { img: "assets/img/story-big/storyg2.jpg" },
    { img: "assets/img/story-big/storyg1.jpg" },
  ];

}
