import { OnInit, Component } from '@angular/core';
import * as $ from 'jquery';
import 'magnific-popup';
import 'jquery-countdown';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-homepage1',
  templateUrl: './homepage1.component.html',
  styleUrls: ['./homepage1.component.css']
})
export class Homepage1Component implements OnInit {
  candidatos: any[] = [];
  constructor() { }
  // Magnific popup
  ngOnInit(): void {
    var coundwn = $('.sigma_countdown-timer');
    $(coundwn).each(function () {
      (<any>$(coundwn)).countdown(coundwn.data('countdown'), function (event: { strftime: (arg0: string) => string; }) {
        $(coundwn).html(
          event.strftime('<div class="counter-box"> <span>%D</span>Days</div><div class="counter-box"> <span>%H</span>Hr</div><div class="counter-box"> <span>%M</span>Min</div><div class="counter-box"> <span>%S</span>Sec</div>')
        );
      });
    });

    ($('.image-popup') as any).magnificPopup({
      type: 'image',
      zoom: {
        enabled: true,
        duration: 300, // duration of the effect, in milliseconds
        easing: 'ease-in-out', // CSS transition easing function
      }
    });
    ($('.video-popup') as any).magnificPopup({
      type: 'iframe',
      zoom: {
        enabled: true,
        duration: 300, // duration of the effect, in milliseconds
        easing: 'ease-in-out', // CSS transition easing function
      }
    });

    this.fetchCandidato();
  }


  // Función para consultar datos del usuario al cargar el componente
  async fetchCandidato() {
    try {
      const response = await fetch('https://thebackend-api.com/candidatos?avatar=true', {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Error al obtener los datos del usuario');
      }
      const data = await response.json();
      if (data.status) {
        this.candidatos = data.data.items; // Guardar la URL devuelta en el modelo avatar
        console.log(this.candidatos);
      }
    } catch (error) {
      console.error('Error en fetchUserData:', error);
    }
  }
  // aboutconfig
  aboutconfig: SwiperOptions = {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    speed: 3500,
    grabCursor: true,
    watchSlidesProgress: true,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    navigation: {
      nextEl: '.about-button-next',
      prevEl: '.about-button-prev'
    }

  };
  bannerimg = "assets/img/banner2.jpg";
  aboutsliderposts = [
    { img: "assets/img/banner/banner1.jpg", subtitle: "Around the world.", title: "We help all", titlespan: "people", titlespanafter: "in need", description: "Gray eel-catfish longnose whiptail catfish smalleye squaretail queen danio unicorn fish shortnose greeneye fusilier fish silver carp nibbler sharksucker tench lookdown catfish", btn1: "Explore", btn2: "Shop Now" },

    { img: "assets/img/banner/banner2.jpg", subtitle: "Around the world.", title: "Our Helping", titlespan: "people", titlespanafter: "in need", description: "Gray eel-catfish longnose whiptail catfish smalleye squaretail queen danio unicorn fish shortnose greeneye fusilier fish silver carp nibbler sharksucker tench lookdown catfish", btn1: "Shop Now", btn2: "Learn More" },

    { img: "assets/img/banner/banner3.jpg", subtitle: "Around the world.", title: "Our Helping", titlespan: "people", titlespanafter: "in need", description: "Gray eel-catfish longnose whiptail catfish smalleye squaretail queen danio unicorn fish shortnose greeneye fusilier fish silver carp nibbler sharksucker tench lookdown catfish", btn1: "Learn More", btn2: "Shop Now" }
  ];
  // Service type
  servicetypeposts = [
    { parentclass: "bg-custom-1 border-custom-right border-sm-bottom", img: "assets/img/help.svg", title: "Participación", subtitle: "Activa" },
    { parentclass: "bg-custom-2 border-custom-right border-sm-bottom", img: "assets/img/money.svg", title: "Conoce a los", subtitle: "Candidatos" },
    { parentclass: "bg-custom-3 border-custom-right border-sm-bottom", img: "assets/img/investor.svg", title: "Ejercite tu", subtitle: "Voto" },
    { parentclass: "bg-custom-4", img: "assets/img/cash.svg", title: "Alcancemos la", subtitle: "Meta" },
  ];
  // Category post
  categoryconfig: SwiperOptions = {
    slidesPerView: 2,
    spaceBetween: 15,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 3,
        spaceBetween: 5,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      992: {
        slidesPerView: 6,
        spaceBetween: 40,
      },
      1200: {
        slidesPerView: 8,
        spaceBetween: 5,
      },
    }
  };

  categoryposts = [
    { img: "assets/img/categories/category1.png", title: "Martha, NY" },
    { img: "assets/img/categories/category2.png", title: "Michle, CA" },
    { img: "assets/img/categories/category3.png", title: "Adem, LA" },
    { img: "assets/img/categories/category4.png", title: "Jimmy, CA" },
    { img: "assets/img/categories/category5.png", title: "Nico, FL" },
    { img: "assets/img/categories/category6.png", title: "Richa, NY" },
    { img: "assets/img/categories/category7.png", title: "Jordan, NY" },
    { img: "assets/img/categories/category8.png", title: "Hemlet, NJ" },
    { img: "assets/img/categories/category9.png", title: "Ricky, LA" },
    { img: "assets/img/categories/category8.png", title: "Casendra, LA" },
    { img: "assets/img/categories/category9.png", title: "Rob, CA" },
    { img: "assets/img/categories/category8.png", title: "Mily, TX" },
    { img: "assets/img/categories/category9.png", title: "Rob, NY" }
  ];
  // Shop Posts
  shopposts = [
    { img: "assets/img/shop/product1.jpg", label1cls: "new", label1: "Veg", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Fancy Wallet", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label1cls: "new", label2cls: "discount", label1: "Veg", label2: "25%", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Streamlined Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product3.jpg", label2cls: "discount", label3cls: "nonveg", label2: "25%", label3: "Meat", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxurious Envelope", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product4.jpg", label1cls: "new", label1: "Veg", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Office Stamp", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product2.jpg", label3cls: "nonveg", label3: "Meat", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Streamlined Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product4.jpg", label1cls: "new", label1: "Veg", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Streamlined Pen", price: "90.00", discountprice: "250.00" }
  ];
  // Icon Posts
  iconposts = [
    { icon: "assets/img/help.svg" },
    { icon: "assets/img/cash.svg" },
    { icon: "assets/img/investor.svg" },
    { icon: "assets/img/help.svg" },
    { icon: "assets/img/money.svg" }
  ];
  // Gallery
  galleryposts = [
    { img: 'assets/img/redes/1.jpg', parentclass: "col-lg-2 col-md-4 col-sm-6" },
    { img: 'assets/img/redes/2.jpg', parentclass: "col-lg-4 col-md-8 col-sm-6" },
    { img: 'assets/img/redes/3.jpg', parentclass: "col-lg-2 col-md-4 col-sm-6" },
    { img: 'assets/img/redes/4.jpg', parentclass: "col-lg-4 col-md-8 col-sm-6" },
  ];
  // Community post
  communityposts = [
    { colclass: "col-xl-4 col-lg-6", img: "assets/img/story-big/about-1.jpg", badge: "Provide a better enviroment", title: "More than One Life Changed", linktext: "Read More" },
    { colclass: "col-xl-4 col-lg-6", img: "assets/img/story-big/about-2.jpg", badge: "Education", title: "Help for Children of the East", linktext: "Read More" },
    { colclass: "col-xl-4 col-lg-12", img: "assets/img/story-big/about-3.jpg", badge: "Food & Meals", title: "More than One Life Changed", linktext: "Read More" },
    { colclass: "col-xl-8 sm-block", img: "assets/img/story-big/about-4.jpg", badge: "Provide a better enviroment", title: "Help for Children of the East", linktext: "Read More" },
    { colclass: "col-xl-4", img: "assets/img/story-big/about-5.jpg", badge: "Health & Care", title: "More than One Life Changed", linktext: "Read More" },
    { colclass: "col-xl-6", img: "assets/img/story-big/about-2.jpg", badge: "Finance", title: "Help for Children of the East", linktext: "Read More" },
    { colclass: "col-xl-6", img: "assets/img/story-big/about-1.jpg", badge: "Education", title: "More than One Life Changed", linktext: "Read More" }
  ];
  // Bestsellers Posts
  bestsellers = [
    { img: "assets/img/shop/product5.jpg", labelcls: "high", label: "Antique", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product6.jpg", labelcls: "uni", label: "Paintings", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product7.jpg", labelcls: "Handmade", label: "Handmade", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Metal Artwork", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product8.jpg", labelcls: "high", label: "Antique", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Voting Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product9.jpg", labelcls: "kids", label: "Handicrafts", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Luxury Pen", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product10.jpg", labelcls: "Ceramicart", label: "Ceramicart", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Map Pin", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product11.jpg", labelcls: "uni", label: "Paintings", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "USA Seal", price: "90.00", discountprice: "250.00" },
    { img: "assets/img/shop/product5.jpg", labelcls: "kids", label: "Handicrafts", carttext: "Add To Cart", soldtitle: "Sold", soldprice: "45,000", goaltitle: "Goal", goalprice: "70,000", title: "Intricate Calendar", price: "90.00", discountprice: "250.00" }
  ];
  featuredproimg = "assets/img/shop/featured.jpg";
  // Campaigns post
  campaignposts = [
    { title: "Help our Veterans come back", description: "Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.", img: "assets/img/donation/article1.jpg", category: "Education", raiseprice: "4585.00", goalprice: "4585.00", linktext: "Donate Now" },
    { title: "Maintain the Congress goverment", description: "Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.", img: "assets/img/donation/article3.jpg", category: "Health", raiseprice: "4585.00", goalprice: "4585.00", linktext: "Donate Now" },
    { title: "Provide a better enviroment", description: "Many desktop publishing package and the web page editor now use lorem Ipsum the model text lorem.", img: "assets/img/donation/article2.jpg", category: "Hygine", raiseprice: "4585.00", goalprice: "4585.00", linktext: "Donate Now" }
  ];


}
