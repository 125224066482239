<div class="inner-wrapper">
	<div class="container-fluid no-padding">
		<div class="row no-gutters overflow-auto">
			<div class="col-md-12">
				<div class="section-2 user-page main-padding">
					<div class="login-sec">
						<div class="login-box">
							<form (ngSubmit)="submitForm({ 
					name: name, 
					lastname: lastname, 
					from: from, 
					about: about, 
					description: description, 
					avatar: avatar 
				  })">
								<h4 class="text-light-black fw-600">Registrar tu información</h4>
								<div class="row">
									<!-- Nombres -->
									<div class="col-lg-6 col-md-12 col-sm-6">
										<div class="form-group">
											<label class="text-light-white fs-14">Nombres</label>
											<input type="text" [(ngModel)]="name" name="name"
												class="form-control form-control-submit" placeholder="Nombres"
												required />
										</div>
									</div>
									<!-- Apellidos -->
									<div class="col-lg-6 col-md-12 col-sm-6">
										<div class="form-group">
											<label class="text-light-white fs-14">Apellidos</label>
											<input type="text" [(ngModel)]="lastname" name="lastname"
												class="form-control form-control-submit" placeholder="Apellidos"
												required />
										</div>
									</div>
									<!-- Desde -->
									<div class="col-12">
										<div class="form-group">
											<label for="universidades-select">Universidades</label>
											<select class="form-control js-example-basic-single"
												id="universidades-select" [(ngModel)]="from" name="universidad">
												<option value="" disabled selected>Selecciona una universidad</option>
												<option *ngFor="let universidad of universidades"
													[value]="universidad.text">
													{{ universidad.text }}
												</option>
											</select>
										</div>
									</div>

									<!-- Acerca de -->
									<div class="col-12">
										<div class="form-group">
											<label class="text-light-white fs-14">Acerca de ti</label>
											<textarea [(ngModel)]="about" name="about"
												class="form-control form-control-submit"
												placeholder="Breve descripción sobre ti" rows="3" required></textarea>
										</div>
									</div>
									<!-- Descripción -->
									<div class="col-12">
										<div class="form-group">
											<label class="text-light-white fs-14">Descripción</label>
											<textarea [(ngModel)]="description" name="description"
												class="form-control form-control-submit"
												placeholder="Describe tus habilidades o intereses" rows="3"
												required></textarea>
										</div>
									</div>
									<!-- Avatar -->
									<div class="col-12">
										<div class="form-group">
											<label class="text-light-white fs-14">Avatar (URL)</label>
											<input type="file" (change)="onFileSelected($event)"
												class="form-control form-control-submit" accept="image/*" />
										</div>

										<div *ngIf="avatar" class="mt-3">
											<img [src]="avatar" alt="Avatar Preview" class="img-thumbnail"
												style="max-width: 150px;" />
										</div>
									</div>
									<!-- Botón de envío -->
									<div class="col-12">
										<button type="submit" class="btn btn-primary btn-block fw-600">
											Registrar
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>