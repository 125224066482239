import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  banner = "assets/img/story2.jpg";
  loading: boolean = false;
  eyed: boolean = false;
  password_type: string = 'password';
  username: string = '';
  password: string = '';
  isError: boolean = false;
  isErrorMessage: string = "";

  showPass() {
    this.eyed = !this.eyed;
    this.password_type = this.password_type === 'text' ? 'password' : 'text';
  }


  async onSubmit() {
    this.isError = false;
    if (this.username && this.password) {
      const url = 'https://thebackend-api.com/login-fveu';
      const payload = {
        username: this.username,
        password: this.password,
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          //throw new Error('Error al iniciar sesión');
          this.isError = true;
        } else {
          const data = await response.json();
          if (data?.status === false) {
            this.isError = true;
          } else {
            console.log(data?.token);
            localStorage.setItem('authToken', data?.token); // Guarda el token o la respuesta en localStorage
            this.router.navigate(['/register']);
          }
        }
      } catch (error) {
        console.error('Error en la autenticación:', error);
      }
    } else {
      console.error('Por favor, completa los campos de usuario y contraseña');
    }
  }
}
