<app-topbar></app-topbar>
<app-navbar></app-navbar>
<!-- breadcrumb -->
<app-breadcrumbs></app-breadcrumbs>
<!-- breadcrumb -->
<!-- Blog -->
<section class="our-articles bg-light-theme section-padding ">
  <div class="container-fluid custom-container">
    <div *ngIf="!userData" class="text-center">
      <p>Cargando detalles del candidato...</p>
    </div>
    <div class="row" *ngIf="userData">
      <div class="col-xl-10 col-lg-10">
        <div class="main-box padding-20 full-width sa-causes-single sa-causes-single-2 mb-md-40">
          <div class="post-wrapper mb-xl-20">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="profile-img full-height">
                  <img [src]="userData?.avatar ? userData.avatar : storybanner" alt="img"
                    class="img-fluid full-width full-height">
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="profile-content">
                  <h4>{{ userData.name }} {{ userData.lastname }}</h4>
                  <p><strong>De:</strong><br> <span class="text-danger">{{ userData.from }}</span></p>
                  <p><strong>Sobre mi:</strong><br> {{ userData.about }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="blog-meta mb-xl-20">
                <h5 class="blog-title text-light-black">Descripción.</h5>
                <p class="text-light-white">{{userData.description}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>