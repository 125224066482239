<app-topbar></app-topbar>
<app-navbar></app-navbar>

<!-- slider -->
<section class="p-relative single-banner">
	<img src="{{bannerimg}}" class="img-fluid full-width" alt="Banner">
	<div class="transform-center">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8 align-self-center">
					<div class="right-side-content text-center">
						<h5 class="text-white">Transformando el Futuro Estudiantil</h5>
						<h1 class="text-white fw-600 fs-60">Elecciones de Federación Estudiantil 2024</h1>
						<p class="text-white fw-400">Descubre a los candidatos que liderarán el cambio en nuestra
							comunidad universitaria. Infórmate, participa y decide el rumbo de nuestra Federación. ¡Tu
							voz importa!</p>
						<a routerLink="/candidatos" class="btn-solid with-line btn-big mt-20"><span>Explorar<i
									class="fas fa-caret-right"></i></span></a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="overlay overlay-bg-dark-2"></div>
</section>
<!-- slider -->
<section class="service-type">
	<div class="row">
		<div class="col-lg-3 col-md-6 {{servicetypepost.parentclass}}" *ngFor="let servicetypepost of servicetypeposts">
			<div class="service-box">
				<div class="service-box-wrapper">
					<div class="service-icon-box">
						<img src="{{servicetypepost.img}}" alt="" class="img-fluid">
					</div>
					<div class="service-text-box">
						<p>{{servicetypepost.title}}</p>
						<h6>{{servicetypepost.subtitle}}</h6>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Browse by category -->
<section class="browse-cat bg-theme-primary section-padding">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-12">
				<div class="section-header-left title">
					<h3 class="text-light-black header-title">Nuestra lista de candidatos </h3>
					<span class="fs-16 align-self-center"><a routerLink="/candidatos">Ver todos</a></span>
				</div>
			</div>
			<div class="col-12">
				<swiper [config]="categoryconfig" class="category-slider swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" *ngFor="let item of candidatos">
							<a routerLink="/candidato/{{item.id}}" class="categories">
								<div class="icon text-custom-white bg-light-green ">
									<img src="{{item.avatar}}" class="rounded-circle" alt="categories">
								</div> <span class="text-light-black cat-name">{{item.name}} {{item.lastname}}</span>
							</a>
						</div>
					</div>
				</swiper>
				<!-- Add Arrows -->
				<div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div>
			</div>
		</div>
	</div>
</section>
<!-- Browse by category -->


<section class="masonary-gallery-sec">
	<div class="container-fluid no-padding">
		<div class="row magnific-gallery">
			<div class="col-lg-6">
				<div class="full-height masonary-box p-relative">
					<div class="masonary-text transform-center">
						<div class="masonary-text-wrapper">
							<div class="icon-box">
								<img src="assets/img/story2.jpg" class="image-fit" alt="img">
							</div>
							<div class="text-box">
								<h3 class="text-custom-white">
									Únete a nuestro Telegram y mantente al día con las últimas novedades
								</h3>
								<a routerLink="https://www.instagram.com/fveuoficial/?hl=es"
									class="text-custom-white fs-14"><i class="fab fa-instagram mr-2"></i>
									@fveuoficial</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="video-box full-height">
					<div class="video_wrapper video_wrapper_full js-videoWrapper">
						<iframe class="videoIframe js-videoIframe" src="https://www.instagram.com/p/DCkaS5yBsvz/?hl=es"
							data-src="https://www.instagram.com/p/DCkaS5yBsvz/?hl=es?autoplay=1"
							allow="autoplay"></iframe>
						<div class="videoPoster js-videoPoster">
							<img src="assets/img/redes/5.jpg" class="image-fit" alt="#">
							<div class="video-inner video-btn-wrapper"> <a
									href="https://www.instagram.com/p/DCkaS5yBsvz/?hl=es" #video class="video-popup"><i
										class="pe-7s-play"></i></a></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row pt-0">
				<div class="{{ gallerypost.parentclass }}" *ngFor="let gallerypost of galleryposts">
					<div class="masonary-item p-relative">
						<a href="{{ gallerypost.img }}" #img class="image-popup">
							<img src="{{ gallerypost.img }}" class="image-fit" alt="img">
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>


<app-footer></app-footer>