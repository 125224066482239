<!-- footer -->
<div class="copyright bg-black">
	<div class="container-fluid custom-container">
		<div class="row">
			<div class="col-lg-8 text-center medewithlove align-self-center"> <a href="#"
					class="text-custom-white">Hecho con <i class="fas fa-heart"></i>
					Juventud del Partido Socialista Unido de Venezuela</a>
			</div>
			<div class="col-lg-4">
				<div class="copyright-text"> <span class="text-white">© <a href="javascript:void(0)"
							class="text-white">JPSUV</a> - 2024 | All Right Reserved</span>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- footer -->